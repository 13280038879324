import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import s from "./style.module.css";
import Footer from "components/Footer/Footer";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        firstName,
        lastName,
        subscriptionStatus: "none",
      });
      navigate("/select-subscription");
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setError(
            <>
              L'adresse e-mail est déjà utilisée. Veuillez en utiliser une autre
              ou <Link to="/login">vous connecter</Link>.
            </>
          );
          break;
        case "auth/invalid-email":
          setError(
            "L'adresse e-mail est invalide. Veuillez entrer une adresse e-mail valide."
          );
          break;
        case "auth/weak-password":
          setError(
            "Le mot de passe est trop faible. Il doit contenir au moins 6 caractères."
          );
          break;
        default:
          setError(`Une erreur s'est produite : ${error.message}`);
      }
    }
  };

  return (
    <div className={s.container}>
      <Link to="/">Revenir à l'accueil</Link>
      <h1>Inscription</h1>
      <form onSubmit={handleSignup} className={s.form}>
        <input
          className={s.input}
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Prénom"
          required
        />
        <input
          className={s.input}
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Nom"
          required
        />
        <input
          className={s.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className={s.input}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          required
        />
        <button type="submit" className={s.btn}>
          Je m'inscris
        </button>
      </form>
      {error && <p className={s.error}>{error}</p>}
      <Footer />
    </div>
  );
}

export default Signup;
