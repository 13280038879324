import React, { useState } from "react";
import { auth, db } from "../../firebase";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import s from "./style.module.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        setMessage(
          "Un e-mail de vérification a été envoyé à votre adresse e-mail."
        );
        return;
      }

      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists) {
        const userData = userDoc.data();

        if (userData.subscriptionStatus !== "active") {
          navigate("/subscription");
        } else {
          navigate("/dashboard");
        }
      } else {
        setError("Les informations de l'utilisateur sont introuvables.");
      }
    } catch (error) {
      setError("Failed to login: " + error.message);
    }
  };

  const handleForgotPassword = async () => {
    setError("");
    setMessage("");

    if (!email) {
      setError("Veuillez entrer votre adresse e-mail.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        "Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail."
      );
    } catch (error) {
      setError(
        "Erreur lors de l'envoi de l'e-mail de réinitialisation : " +
          error.message
      );
    }
  };

  return (
    <div>
      <h1 className={s.title}>Connexion</h1>
      <form onSubmit={handleLogin} className={s.form}>
        <input
          className={s.mail}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className={s.password}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit" className={s.btn}>
          Connexion
        </button>
      </form>
      {error && <p className={s.error}>{error}</p>}
      {message && <p className={s.message}>{message}</p>}
      <button onClick={handleForgotPassword} className={s.forgotPassword}>
        Mot de passe oublié ?
      </button>
    </div>
  );
}

export default Login;
