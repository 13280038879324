import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();

        setCurrentUser({ ...user, ...userData });

        if (!initialCheckDone) {
          if (userData.subscriptionStatus !== "active") {
            navigate("/select-subscription");
          } else {
            navigate("/dashboard");
          }
          setInitialCheckDone(true);
        }
      } else {
        setCurrentUser(null);
        if (!initialCheckDone) {
          navigate("/");
          setInitialCheckDone(true);
        }
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [navigate, initialCheckDone]);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
