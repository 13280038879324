import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import s from "./style.module.css";

function PaymentPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSDKReady, setSDKReady] = useState(false);

  const { amount, description, checkoutId } = location.state || {};

  // Charger dynamiquement le script SDK SumUp
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js";
    script.onload = () => setSDKReady(true);
    script.onerror = () => navigate("/");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);

  useEffect(() => {
    if (isSDKReady && checkoutId) {
      window.SumUpCard.mount({
        id: "sumup-card",
        checkoutId: checkoutId,
        onResponse: async (type, body) => {
          console.log("SumUp response type:", type);
          console.log("SumUp response body:", body);

          if (type === "success") {
            try {
              const response = await fetch("https://api.clubcrm.fr/api/save-transaction", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  transactionId: body.transaction_code,
                  status: body.status,
                  amount: body.amount,
                  currency: body.currency,
                  userId: body.customer_id,
                }),
              });

              if (!response.ok) {
                throw new Error("Failed to save transaction data");
              }

              const data = await response.json();
              console.log("Transaction saved:", data);
              navigate("/payment-success");
            } catch (error) {
              console.error("Error saving transaction:", error);
              alert("Payment was successful but we couldn't save the transaction. Please contact support.");
              navigate("/subscription");
            }
          } else {
            console.error("Payment failed:", body);
            alert("Payment failed, please try again.");
            navigate("/subscription");
          }
        },
      });
    }
  }, [isSDKReady, checkoutId, navigate]);

  return (
    <div className={s.container}>
      {checkoutId ? (
        <div>
          <h1>Processing Payment for {description}</h1>
          <p>Total Amount: {amount}€</p>
          <div id="sumup-card" />
        </div>
      ) : (
        <h1>Loading payment information...</h1>
      )}
    </div>
  );
}

export default PaymentPage;
