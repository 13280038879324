import React from "react";
import { useNavigate, Link } from "react-router-dom";
import s from "./style.module.css";
import { useAuth } from "components/context/authContext";

function SubscriptionPage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleSubscription = async () => {
    const amount = 30;
    const description = "Abonnement Annuel";
    const email = currentUser?.email;
    const firstName = currentUser?.firstName;
    const lastName = currentUser?.lastName;
    const userId = currentUser?.uid;

    if (!email || !firstName || !lastName || !userId) {
      alert("Vous devez être connecté pour vous abonner.");
      return;
    }

    try {
      // 1. Créer un client sur SumUp
      const customerResponse = await fetch("https://api.clubcrm.fr/api/create-customer", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, firstName, lastName, userId }), // Passer le userId ici
      });

      if (!customerResponse.ok) {
        const errorText = await customerResponse.text();
        throw new Error(`Failed to create customer: ${errorText}`);
      }

      const customerData = await customerResponse.json();
      const customerId = customerData.customerId; // Le customerId est récupéré depuis la réponse du backend

      // 2. Créer une session de checkout avec le customerId
      const checkoutResponse = await fetch("https://api.clubcrm.fr/api/create-checkout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount, currency: "EUR", description, userId }), // Utiliser le userId ici
      });

      if (!checkoutResponse.ok) {
        const errorText = await checkoutResponse.text();
        throw new Error(`Failed to create checkout session: ${errorText}`);
      }

      const checkoutData = await checkoutResponse.json();

      if (checkoutData.checkoutId) {
        navigate("/payment", {
          state: { amount, description, checkoutId: checkoutData.checkoutId },
        });
      } else {
        throw new Error("Failed to retrieve payment URL");
      }
    } catch (error) {
      alert("Échec du paiement: " + error.message);
    }
  };

  return (
    <div className={s.container}>
      <h1 className={s.title}>Choisissez votre plan d'abonnement</h1>
      <div>
        <h2 className={s.subtitle}>Abonnement Annuel</h2>
        <p>30€ par an</p>
        <button className={s.button} onClick={handleSubscription}>
          S'abonner au plan Annuel
        </button>
        <p className={s.description}>
          Le plan Annuel pourra vous être offert par votre RS sous condition du
          minimum de PV annuel requis par votre statut.
        </p>
      </div>
      <Link to="/" className={s.link}>
        Retour à l'accueil
      </Link>
    </div>
  );
}

export default SubscriptionPage;
